import { useSearchParams } from 'hooks/routing';
import React, { Fragment, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { pathManager } from 'routes';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button, { RouterButton } from '@libs/ui/components/button';
import Typography from '@libs/ui/components/typography';

interface ImportantNotificationsPopoverProps extends React.PropsWithChildren {
  count: number;
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: 'transparent',
    padding: 0,
    margin: '0 !important',
    width: 350,
    maxWidth: 350,
  },
  arrow: {
    color: 'white',
  },
}));

export const ImportantNotificationsPopover = ({
  count,
  children,
}: ImportantNotificationsPopoverProps) => {
  const params = useSearchParams();
  const matchRoute = useRouteMatch({
    path: pathManager.notifications.generatePath(),
    exact: true,
  });
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(true);
  const closePopover = () => {
    setIsOpen(false);
  };
  const isOnImportantNotificationsPage =
    matchRoute?.isExact && params['type'] === 'important';
  const isOpenFinal = !!count && !isOnImportantNotificationsPage && isOpen;

  useEffect(() => {
    if (isOnImportantNotificationsPage) {
      closePopover();
    }
  }, [isOnImportantNotificationsPage]);

  return (
    <Tooltip
      onClose={closePopover}
      open={isOpenFinal}
      disableFocusListener
      disableHoverListener
      arrow
      disableTouchListener
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title={
        <Fragment>
          <Card elevation={8}>
            <CardContent>
              <Typography variant="body1" component="div" textAlign="center">
                You have {count} important notification(s)
              </Typography>
            </CardContent>
            <CardActions>
              <Box mx={2} width="100%" mb={2}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <RouterButton
                      to={{
                        pathname: pathManager.notifications.generatePath(),
                        search: '?type=important',
                      }}
                      fullWidth
                      variant="contained"
                      color="info"
                    >
                      check it
                    </RouterButton>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="info"
                      onClick={closePopover}
                    >
                      Close this
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardActions>
          </Card>
        </Fragment>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};
